import { Default, Detail, List } from "./views";



const Module = {
    name: "biviewer",
    defaultRoutePath: "/bis/biviewer",
    menuItem: {
        title: "Business Intelligence",
        href: "/bis/biviewer",
        icon: "faGauge"
    },

    internalMenuItems: {
        activities: [
            {
                title: "Business Intelligence",
                href: "/bis/biviewer",
            },
            // {
            //     title: "BI Configurations",
            //     href: "/bis/configuration",
            // },
        ],
        wizards: []
    },

    routes: [
        {
            path: "/bis/biviewer",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
    ],
    dataProviders: [
        {
            "name": "persons",
            "endpoint": "/api/persons/person",
        },
        {
            "name": "biviewer",
            "endpoint": "/api/bis/viewer",
        },
        {
            "name": "legalentities",
            "endpoint": "/api/companies/legalentity",
        },
       
    ]
}

export default Module;
