import { useTranslation } from 'react-i18next'

export const useFormatter = () => {
    const { t, i18n } = useTranslation();

    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    const distanceformatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        maximumFractionDigits: 0
    });

    const formatDate = (string) => {
        let formatted = '-';
        if (string) {
            try {
                formatted = dateformatter.format(new Date(string));
            } catch (e) {
                formatted = <em>!{ JSON.stringify(string) } </em>;
            }
        }
        return formatted;
    }

    const formatDistance = (string) => {
        let formatted = '-';
        if (string) {
            try {
                formatted = distanceformatter.format(string);
            } catch (e) {
                formatted = <em>!{ JSON.stringify(string) } </em>;
            }
        }
        return formatted;
    }

    return {
        formatDate,
        formatDistance
    }

};
