import React from 'react';
import { Box } from '@mui/joy';
import { LegalEntityAvatar } from  '../Snippet'

const CellLegalEntity = ({ value }) => {

    return (
        <Box className={"list-element-cell cell-legalentity"}>
            <LegalEntityAvatar  name={value?.name} url={value?.logo?.url} />
            <span className="name" title={value?.name}>{value?.name}</span>
        </Box>
    );
}

export default CellLegalEntity