import React from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'
import Termometer from "./Termometer";
import Tag from "./Tag";
import { Icon } from "../../assets";

const VehicleInstalledTermometer = ({ contractStartDate, contractEndDate, contractTotalKM, lastTraceKMValue, lastTraceKMDate, layout = "pile" }) => {
    const { t, i18n } = useTranslation();

    const csd = new Date(contractStartDate);
    const ced = new Date(contractEndDate);
    const td = new Date();
    const kmd = new Date(lastTraceKMDate ?? null);

    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);
    const numberformatter = Intl.NumberFormat(i18n.resolvedLanguage);
    const percentformatter = Intl.NumberFormat(i18n.resolvedLanguage, { style: "percent" })

    const contractTotalDuration = Math.floor((ced - csd) / (1000 * 60 * 60 * 24));
    const contractEnlapsed = Math.floor((td - csd) / (1000 * 60 * 60 * 24));
    const kmTraceFromToday = Math.floor((td - kmd) / (1000 * 60 * 60 * 24));

    const contracEnlapsedOverTotal = contractEnlapsed / contractTotalDuration;
    const kmPercentage = lastTraceKMValue / contractTotalKM;
    

    const kmToContractEnlapsed = kmPercentage / contracEnlapsedOverTotal;

    return (
        <Box className={"vehicle-termometer vehicle-installed-termometer layout-"+layout}>
            <Box className="trace">
                <Termometer percentage={kmPercentage} color={kmTraceFromToday > process.env.REACT_APP_TRACE_KM_MAX_MISSING_DAYS ? "danger" : ((kmPercentage < 0.7) ? "secondary" : "warning")} hint={t("Last recorded KM") + " " + numberformatter.format(lastTraceKMValue) + " " + t("out of") + " " + numberformatter.format(contractTotalKM) + " (" + dateformatter.format(kmd) +" - "+numberformatter.format(kmTraceFromToday)+" "+t("days ago")+ ")"} hintposition="top" />
            </Box>
            <Box className="contract">
                <Termometer percentage={contracEnlapsedOverTotal} color={(contracEnlapsedOverTotal < 0.7) ? "secondary" : "warning"} hint={t("Contract end date") + ": " + dateformatter.format(ced)} hintposition={layout == "pile" ? "bottom" : "top"} />
            </Box>
            <Box className="analysis">
                {
                    (kmToContractEnlapsed > process.env.REACT_APP_MISMATCH_KM_TIME_MAX_PERC || kmToContractEnlapsed < process.env.REACT_APP_MISMATCH_KM_TIME_MIN_PERC) && (
                        <>
                            <Icon icon="faArrowTurnLeft" className="upIndicator"/>
                            <Tag color="danger" size="small" label={percentformatter.format(kmToContractEnlapsed)} icon="faTriangleExclamation" />
                            <Icon icon="faArrowTurnDownLeft" className="downIndicator"/>
                        </>
                    )
                }                
            </Box>
        </Box>
    )
}

export default VehicleInstalledTermometer;