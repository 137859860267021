import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Tag, VehicleInstalledTermometer, ExpirationTermometer, VehicleRenter } from '../Snippet';

const RelationshipCar = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const tagcolors = {
        installed: "success",
        running: "success",
        ordered: "secondary",
        substitute: "warning",
        archived: "danger"
    }

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    const getContract = (v) => {
        return v?.contract ? v?.contract[0] : null;
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            {/* <textarea>{ JSON.stringify(v)}</textarea> */}
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                <Box className={"makeandorder"}>
                    <span className={"make"} title={(v?.setup?.make || '') + " " + (v?.setup?.model || '-')  + " " + (v?.setup?.name || '-')}>{(v?.setup?.make || '-' ) + " " + (v?.setup?.model || '-') + " " + (v?.setup?.name || '')}</span>
                    <span className={"contract"}>{t('contract')}: {getContract(v)?.contractNumber}</span>
                </Box>
                <Box className={"plate"}>
                    {v?.plate}
                </Box>
                <Box className={"ownership"}>
                    {v?.type === 'OWNED' && (<span className="owned">{t(getType(v))}</span>)}
                    {/* {v?.type === 'RENT' && (
                        <VehicleRenter renterName={v?.renter.name} logo={v?.renterLogo} fringeBenefitValue={v?.fringeBenefitValueMonth} fringeBenefitCurrency={v?.fringeBenefitCurrency} />
                    )} */}
                        <VehicleRenter renterName={v?.renter?.name} logo={v?.renter?.logo ?? `https://placehold.co/40x40?text=${v.renter?.name}`} fringeBenefitValue={1230} fringeBenefitCurrency={'eur'} />

                </Box>
                <Box className={"status"}>
                    {/* {v?.type === 'RENT' && (
                        <Tag color={(tagcolors[getStatus(v)])} size="small" label={t(getStatus(v))} />
                    )} */}
                        <Tag color={(tagcolors[getStatus(v)])} size="small" label={t(getStatus(v))} />

                </Box>
                <Box className={"analysis analysis-" + getStatus(v)}>
                    {(v?.status === 'INSTALLED' && v?.type === 'RENT') && (
                        <VehicleInstalledTermometer contractStartDate={v?.contractStartDate} contractEndDate={v?.contractEndDate} contractTotalKM={v?.contractTotalKM} lastTraceKMValue={v?.lastTraceKm?.value} lastTraceKMDate={v?.lastTraceKm?.traceDay} />
                    )}
                    {(v?.status === 'ORDERED' && v?.type === 'RENT') && (
                        <ExpirationTermometer installationDate={v?.orderOrderDate} expirationDate={v?.expectedArrivalDay} />
                    )}
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}


export default RelationshipCar


