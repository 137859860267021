import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const limit = 1;

const More = (value) => {
    const length = (typeof value.value === 'object') ? Object.keys(value.value).length : 0;

    return length > limit && (
        <ListItem className={'cell-list-item-count cell-employments-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    );
}


const CellEmployments = ({ value }) => {
    const getValueAsArray = () => {
        return Array.isArray(value) ? value.slice(0, limit) : typeof value !== null ? [value] : [];
    }

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String) {
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"list-element-cell cell-employments"}>
            <List className="cell-employments-list">
                {getValueAsArray().map((employment, k) => {
                    if (!employment) return;
                    return (
                        <ListItem key={k} className={'cell-employments-list-item'}>
                            <span className="name" title={employment?.role +"@" +employment?.legalEntity?.name}>{employment?.role}@{employment?.legalEntity?.name}</span>
                            {/* <span className="role" title={employment?.role}>{employment?.role}@{employment?.legalEntity?.name}</span> */}
                        </ListItem>
                    );
                })}
                
                <More value={value} />
            </List>
        </Box>
    );
}

export default CellEmployments