import React from 'react';
import { Button, List as ListComponent } from '../../';



const ViewDocumentList = (params) => {

    return <>
        <div className=" form-element col-12">

            <ListComponent
                {...params}
                rightButtons={[
                ]}
                dataProviderName='documents/document'
                title={"Documents"} />
        </div>
    </>
}

export default ViewDocumentList



