//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';

import {
    DocumentModule,
    LegalEntityModule,
    BiViewerModule, 
    AuthModule,
    BiModule,
    PersonModule,
    UserModule,
    RoleModule,
    PermissionModule,
    BusinessUnitModule,
    LocationModule,
    EmploymentModule,
    DashboardModule,
} from './mtska-frontend-modules';



const AppExecutive = () => {
    const config = {
        title: process.env.REACT_APP_SHORT_NAME,
        modules: [
            DashboardModule,
            LegalEntityModule,
            PersonModule,
            BiViewerModule,
            AuthModule,
            //DocumentModule,
            BiModule,
            LocationModule,
            EmploymentModule,
            UserModule,
            RoleModule,
            PermissionModule,
            BusinessUnitModule,
        ],
        copyright: '© Elletre',
        privacy: {
            label: 'Privacy policy',
            url: 'https://www.elletre.it'
        },
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENTID,
            authorizationParams: {
                redirect_uri: process.env.REACT_APP_AUTH0_AUTHORIZATIONPARAMS_REDIRECT_URI,
            }
        }
    }

    return (
        <App concreteconfig={config} />
    )
}

export default AppExecutive;