import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Tag, LegalEntityAvatar } from '../Snippet';
import { Anchor } from '../Typography';

const RelationshipLegalEntity = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <LegalEntityAvatar name={v?.name} url={v?.logoURL} />
                <Box className="name">
                    {v?.name}
                </Box>
                <Box className="url coupled">
                    <Box className="label">{t('Web site')}</Box>
                    <Box className="value">
                        <Anchor href={v?.website} target='_blank'>{v?.website?.replace('https://', '')}</Anchor>
                    </Box>
                </Box>
                <Box className={"tags norightborder"}>
                    {
                        v?.roles?.map((vr, kr) => (
                            <Tag color={"secondary"} size="small" label={t(vr)} />
                        ))
                    }
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipLegalEntity



