import React from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'

const Termometer = ({percentage, color, hint, hintposition = "top"}) => {
    const { i18n } = useTranslation();

    return (
        <Box className={"termometer termometer-"+color+" termometer-"+hintposition}>
            <Box className="rail">
                <Box className={"runner runner-"+color} sx={{width:((percentage > 1 ? '100%': parseInt(percentage * 100) + '%' ))}}></Box>
            </Box>
            <Box className="percentage">
                { new Intl.NumberFormat(i18n.resolvedLanguage, {style:"percent"}).format(percentage) }
            </Box>
            <Box className={"hint hint-"+color}>{ hint }</Box>
        </Box>
    )
}

export default Termometer;