import React from 'react';
import { useState, useEffect } from 'react';
import { Button, List as ListComponent, useDrawer } from '../../../../mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid'
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';

const List = (params) => {
   const { t } = useTranslation();
   const apiRef = useGridApiRef();
   const getselected = () => {
      return [...apiRef.current.getSelectedRows()].map(([name, value]) => ({ name, value }))
   }

   const [cap, setCap] = useState();

   const { item } = useDataProvider('configs/config');
   useEffect(() => {
      if (typeof item?.data?.capabilities === 'undefined') return;
      setCap(item.data.capabilities)
   }, [item])

   const { activate } = useDrawer();

   const { loadAll } = useDataProvider("persons/person");
   return <>
      {cap && (
         <ListComponent
            {...params}
            apiRef={apiRef}
            enableSelection
            rightButtons={[
               <Button
                  disabled={cap && !cap['persons/person.create']}
                  onClick={() => {
                     activate('persons/detail', {detailStack: params.detailStack ?? {}});
                  }}
                  label={"New"}
               />,
               <Button
                  disabled={cap && !cap['persons/person.create']}
                  onClick={() => {
                     activate('persons/importer', {}, (data) => { loadAll(); });
                  }}
                  label={"Import"} />,
            ]}


            dataProviderName='persons/person'
            title={"People"} />
      )}</>
}

export default List;


