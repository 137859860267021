import React from 'react';
import { Box } from '@mui/joy';
import { Anchor } from '../Typography';
import { Icon } from '../../assets';

const CellBadge = ({ value }) => {

    return (
        <Box className={"list-element-cell cell-link"}>
            <Anchor href={value} className="name" title={value} target="_blank">
                <Icon icon="faGlobe" />
            </Anchor>
        </Box>
    );
}

export default CellBadge