import React from 'react';
import { useState, useEffect } from 'react';
import { Confirm } from '../Dialog';
import { Box, Table, Select as SelectJoy, Option } from '@mui/joy';
import { Text } from '../Typography';

const Previewer = ({ excelData, model, defaultMapping = {}, onMappingChange }) => {


    const [mapping, setMapping] = useState({});

    useEffect(() => {
        setMapping(defaultMapping);
    }, [defaultMapping])

    useEffect(() => {
        onMappingChange && onMappingChange(mapping);
    }, [mapping])


    const [hasMappingConflicts, setHasMappingConflicts] = useState(false);

    const applyMapping = (xlscol, newValue) => {

        setHasMappingConflicts(false);

        const newMapping = { ...mapping, [xlscol]: newValue };

        if (newValue === "") {
            delete newMapping[xlscol];
        }

        const previousMapping = Object.keys(mapping).find(key => mapping[key] === newValue);
        if (typeof previousMapping !== "undefined") {
            delete newMapping[previousMapping];
        }
        setMapping(newMapping);
    }

    const handleMappingChange = (e, newValue, xlscol) => {

        const previousMapping = Object.keys(mapping).find(key => mapping[key] === newValue);
        if (typeof previousMapping === "undefined") {
            applyMapping(xlscol, newValue);
            return;
        }

        setHasMappingConflicts({ previousMapping: previousMapping, xlscol: xlscol, newValue: newValue })
    }

    return excelData ? (
        <Box className="importer-data-wrapper">
            
            <Box className="importer-data">
                <Text level={"title-md"}>Preview delle prime  righe</Text>
                <Table>
                    <thead>
                        <tr>
                            {
                                Object.keys(excelData[0]).map((key) => (
                                    <th key={key} title={key}>{key}</th>
                                ))
                            }
                        </tr>
                        <tr>
                            {
                                Object.keys(excelData[0]).map((key) => (
                                    <th key={key} title={key}>
                                        <SelectJoy
                                            name={key}
                                            onChange={(e, v) => handleMappingChange(e, v, key)}
                                            variant="outlined"
                                            className="form-element-field form-element-field-select"
                                            value={mapping[key] ?? ''}
                                        >
                                            <Option defaultChecked value="" >-</Option>
                                            {
                                                Array.isArray(model) && model.map((field, k) => (
                                                    <Option key={k} value={field.key}>{field.label}</Option>
                                                ))
                                            }
                                        </SelectJoy>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            excelData.map((item, rowid) => (
                                <tr key={rowid}>
                                    {
                                        Object.keys(item).map((key) => (
                                            <td key={key} title={item[key]}>{item[key]}</td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                {hasMappingConflicts && (
                    <Confirm
                        oklabel="Cambia"
                        okcallable={() => { applyMapping(hasMappingConflicts.xlscol, hasMappingConflicts.newValue) }}
                        kolabel="Annulla"
                        kocallable={() => { setHasMappingConflicts(false) }}
                        title="Conflitto di attribuzione">Hai già assegnato il campo "{hasMappingConflicts.newValue}" alla colonna "{hasMappingConflicts.previousMapping}", vuoi assegnarlo a "{hasMappingConflicts.xlscol}"?</Confirm>
                )}
            </Box>
        </Box>
    ) : null;
}

export default Previewer;