import React from "react";
import { Box } from "@mui/joy";

const LegalEntityAvatar = ({name, size = "default", url = null}) => {

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String){
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"company-avatar company-avatar-"+size}>
            {url && (
                <img src={url} />
            )}
            {!url && (
                <span className="name" title={name}>{getFirstCharacter(name)}</span>
            )}
        </Box>
    )
}

export default LegalEntityAvatar;