import { Default } from "./views";

const Module = {
    name: "dashboard",
    defaultRoutePath: "/dashboard",
    menuItem: {
        title: "dashboard",
        href: "/dashboard",
        icon: "faHome"
    },
    internalMenuItems: {
        activities: [
            {
                title: "dashboard",
                href: "/dashboard",
            },
        ],
        wizards: []
    },
    routes: [
        {
            path: "/dashboard",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                }
            ],
        },
    ],
    drawerRoutes: [     
    ],
   
}

export default Module;
