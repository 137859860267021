import React from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'
import Termometer from "./Termometer";

const ExpirationTermometer = ({ installationDate, expirationDate, orderDate = null, expectedInstallationDate = null }) => {
    const { t, i18n } = useTranslation();
    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    let startd, endd, hint;

    if (installationDate && expirationDate) {
        startd = new Date(installationDate);
        endd = new Date(expirationDate);
        hint = t('Expiration date')
    } else if (orderDate && expectedInstallationDate) {
        startd = new Date(orderDate);
        endd = new Date(expectedInstallationDate);
        hint = t('Expected installation date')
    }

    const td = new Date();

    const awaitduration = Math.floor((endd - startd) / (1000 * 60 * 60 * 24));
    let awaitlapsed = Math.floor((td - startd) / (1000 * 60 * 60 * 24));
    if (awaitlapsed > awaitduration) awaitlapsed = awaitduration;

    const awaitenlapsedpercentage = awaitlapsed / awaitduration;

    let color = "success";
    if (awaitenlapsedpercentage == 1) {
        color = "danger"
    } else if (awaitenlapsedpercentage >= 0.7) {
        color = "warning"
    }

    return (
        <Box className="general-termometer">
            <Termometer percentage={awaitenlapsedpercentage} color={color} hint={hint + " " + dateformatter.format(endd)} hintposition="top" />
        </Box>
    )
}

export default ExpirationTermometer;