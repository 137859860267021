import React, { Fragment } from "react"
import CellDrawer from "./CellDrawer";
import CellEmail from "./CellEmail";
import CellSimple from "./CellSimple";
import CellPeople from "./CellPeople";
import CellPerson from "./CellPerson";
import CellLegalentities from "./CellLegalentities";
import CellLegalEntity from "./CellLegalEntity";
import CellBis from "./CellBis";
import CellCount from "./CellCount";
import CellDate from "./CellDate";
import CellVehiclePlate from "./CellVehiclePlate";
import CellCurrency from "./CellCurrency";
import CellCarSetup from "./CellCarSetup";
import CellBoolean from "./CellBoolean";
import CellStatus from "./CellStatus";
import CellCars from "./CellCars";
import CellDrivers from "./CellDrivers";
import CellTranslatable from "./CellTranslatable";
import CellEmployees from "./CellEmployees";
import CellEmployments from "./CellEmployments";
import CellTraceKmsLast from "./CellTraceKmsLast";
import CellPool from "./CellPool";
import CellPools from "./CellPools";
import CellBadge from "./CellBadge";
import CellLink from "./CellLink";


const Cell = ({ detailStack, type, columnRendered, value, row, drawerRoute, fieldDataProviderName }) => {

    if (type === 'detail') return (
        <CellDrawer
            drawerRoute={drawerRoute}
            value={value}
            filters={row}
            detailStack={detailStack}
        />
    )

    if (type === 'relation-list') return (
        <CellDrawer
            dataProviderName={fieldDataProviderName}
            value={value}
            filters={row}
            detailStack={detailStack}

        />
    )

    if (type === 'relation-detail') return (
        <CellDrawer
            dataProviderName={fieldDataProviderName}
            value={value}
            filters={row}
            showDetail
            detailStack={detailStack}

        />
    )

    if (type === 'email') return (
        <CellEmail
            value={value}
        />
    )

    if (type === 'cars') return (
        <CellCars
            value={value}
        />
    )

    if (type === 'drivers') return (
        <CellDrivers
            value={value}
        />
    )

    if (type === 'traceKmsLast') return (
        <CellTraceKmsLast
            value={value}
        />
    )

    if (type === 'pools') return (
        <CellPools
            value={value}
        />
    )

    if (type === 'pool') return (
        <CellPool
            value={value}
        />
    )

    if (type === 'date') return (
        <CellDate
            value={value}
        />
    )

    if (type === 'boolean') return (
        <CellBoolean
            value={value}
        />
    )

    if (type === 'people') return (
        <CellPeople
            value={value}
        />
    )

    if (type === 'person') return (
        <CellPerson
            value={value}
        />
    )

    if (type === 'legalentities') return (
        <CellLegalentities
            value={value}
        />
    )

    if (type === 'employees') return (
        <CellEmployees
            value={value}
        />
    )

    if (type === 'employments') return (
        <CellEmployments
            value={value}
        />
    )
    
    if (type === 'legalEntity') return (
        <CellLegalEntity
            value={value}
        />
    )

    if (type === 'currency') return (
        <CellCurrency
            value={value}
        />
    )

    if (type === 'bis') return (
        <CellBis
            value={value}
        />
    )

    if (type === 'count') return (
        <CellCount
            value={value}
            columnRendered={columnRendered}
        />
    )

    if (type === 'vehiclePlate') return (
        <CellVehiclePlate
            value={value}
        />
    )

    if (type === 'carSetup') return (
        <CellCarSetup
            value={value}
        />
    )

    if (type === 'status') return (
        <CellStatus
            value={value}
        />
    )

    if (type === 'translatable') return (
        <CellTranslatable
            value={value}
        />
    )

    if (type === 'badge') return (
        <CellBadge
            value={value}
        />
    )

    if (type === 'link') return (
        <CellLink
            value={value}
        />
    )

    return (
        <CellSimple
            value={typeof value === 'object' ? JSON.stringify(value) : value}
            type={type}
        />
    )

    return ''

};

export default Cell
