import React from 'react';
import { Box } from '@mui/joy';
import { useFormatter } from '../../utils';

const CellTraceKmsLast = ({ value, type = 'tracekmslast' }) => {

    const { formatDate, formatDistance } = useFormatter();

    return (
        <Box className={"list-element-cell cell-" + type}>
            <span class="trace">{ formatDistance(value?.value) }</span>
            {/* <textarea>{ JSON.stringify(value)}</textarea> */}
            <span class="date">{ formatDate(value?.traced_at) }</span>
        </Box>
    )
}

export default CellTraceKmsLast