import { Default, Detail, List } from "./views";


const Module = {
    name: "legalentities",
    defaultRoutePath: "/legalentities/legalentity",
    menuItem: {
        title: "legalentities",
        href: "/legalentities/legalentity",
        icon: "faBuilding",
        hidden: true
    },

    internalMenuItems: {
        activities: [
            {
                title: "legalentities",
                href: "/legalentities/legalentity",
            },
            {
                title: "shop",
                href: "/legalentities/businessunit",
            },
        ],
        wizards: []
    },
    routes: [
        {
            path: "/legalentities/legalentity",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
    ],
    
}

export default Module;
