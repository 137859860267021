import React from 'react';
import { List as ListComponent } from '../../../../mtska-frontend-app-component';

const List = (params) => {
   return  <ListComponent dataProviderName='bis/viewer' {...params} />
}

export default List;


