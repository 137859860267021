import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { TraceKmTermometer } from '../Snippet';
import Dump from '../../Dump';

const RelationshipPool = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    const distanceformatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        maximumFractionDigits: 0
    });
    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    let trace_date
    if (typeof v?.traced_at != typeof undefined) {
        trace_date = dateformatter.format(new Date(v.traced_at));
    } else {
        trace_date = '-';
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent sx={{display:'flex'}} className={"relationship-element-content"}>
                <Box className="coupled">
                    <Box className="label">{t('name')}</Box>
                    <Box className="value">
                        <span>{v?.name}</span>
                    </Box>
                </Box>
                <Box className={"coupled"}>
                    <Box className="label">{t('driver')}</Box>
                    <Box className="value">
                        <span>nome driver</span>
                    </Box>
                </Box>
                <Box className={"coupled"}>
                    <Box className="label">{t('plate')}</Box>
                    <Box className="value">

                        {Array.isArray(v?.cars) && v.cars.map((car,k) => (
                            <div>{car.plate}</div>
                        ))}
                    </Box>
                </Box>
            </ListItemContent>
            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {/* {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)} */}
        </ListItem>
    )
}

export default RelationshipPool;



