import createConstants from "./constants";
import createService from "./service";


export const createAction = (dataProviderName, multipart) => {

    const service = createService(dataProviderName, multipart);
    const constants = createConstants(dataProviderName);

    function _delete(id) {
        return dispatch => {
            dispatch(request());
            return service._delete(id)
                .then(
                    items => dispatch(getAll()),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName: dataProviderName, type: constants.DELETE_REQUEST }
        }
        function success(id) {
            return { dataProviderName: dataProviderName, type: constants.DELETE_SUCCESS, id }
        }
        function failure(id, error) {
            return { dataProviderName: dataProviderName, type: constants.DELETE_FAILURE, id, error }
        }
    }


    function save(item) {

        return dispatch => {
            dispatch(request());

            return service.save(item)
                .then(
                    item => {
                        dispatch(success(item));
                        dispatch(getAll());
                    },
                    error => {
                        dispatch(failure(error));
                    }
                );
        };

        function request() {
            return { dataProviderName: dataProviderName, type: constants.SAVE_REQUEST }
        }
        function success(item) {
            return { dataProviderName: dataProviderName, type: constants.SAVE_SUCCESS, item }
        }
        function failure(error) {
            return { dataProviderName: dataProviderName, type: constants.SAVE_FAILURE, ...error };
        }


    }


    function getAll(filterModel = {}, sortModel = {}, page = '', per_page = '') {
        return dispatch => {
            dispatch(request());

            return service.getAll(filterModel, sortModel, page, per_page)
                .then(
                    items => {
                        dispatch(success(items));
                    },
                    error => {
                        dispatch(failure(error));
                    }
                );
        };

        function request() {
            return { dataProviderName: dataProviderName, type: constants.GETALL_REQUEST }
        }
        function success(items) {
            return { dataProviderName: dataProviderName, type: constants.GETALL_SUCCESS, items }
        }
        function failure(error) {
            return { dataProviderName: dataProviderName, type: constants.GETALL_FAILURE, ...error };
        }
    }

    function getOne(id) {
        return dispatch => {

            dispatch(request());

            return service.getById(id)
                .then(
                    item => dispatch(success(item)),
                    error => dispatch(failure(error))
                );
        };

        function request() {
            return { dataProviderName: dataProviderName, type: constants.GETONE_REQUEST }
        }

        function success(item) {
            return { dataProviderName: dataProviderName, type: constants.GETONE_SUCCESS, item }
        }

        function failure(error) {
            return { dataProviderName: dataProviderName, type: constants.GETONE_FAILURE, id, error }
        }
    }

    return {
        save,
        getAll,
        getOne,
        _delete,
    };

}
