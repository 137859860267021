import React from "react"
import { Box, ModalClose } from "@mui/joy"
import {  Text, Importer as ImporterComponent } from "../../../../mtska-frontend-app-component";

const Importer = (params) => {

   return <>
      {params.isDrawer && (
         <Box className="drawer-section-header" >
            <ModalClose />
            <Text level="title-md" icon >Import People</Text>
         </Box>
      )}
      <ImporterComponent dataProviderName='persons/person' {...params} filters="persons/person"></ImporterComponent>
   </>

}

export default Importer;


