import { React, createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from "./actions";

const DataProviderContext = createContext();

export const DataProvider = ({ children }) => {


    let dataProviderName = ''
    const setDataProviderName = (name) => {
        dataProviderName = name;
    }

    const items = null;
    const total = null;
    const loading = null;
    const widgets = null;

    const item = null;
    const errorMessage = null;
    const errors = null;
    const detailConfig = null;
    const modelName = null;
    const formConfig = null;
    const tableConfig = null;


    // dispatchable actions
    const dispatch = useDispatch();
    const dataProviderActions = createAction(dataProviderName)

    const loadAll = (filterModel, sortModel, page, per_page) => {
        dispatch(dataProviderActions.getAll(filterModel, sortModel, page, per_page))
    }

    const loadOne = (id) => {
        return dispatch(dataProviderActions.getOne(id))
    }

    const save = (values) => {
        dispatch(dataProviderActions.save(values))
    }

    return (
        <DataProviderContext.Provider value={{
            items,
            total,
            loading,
            item,
            errorMessage,
            errors,
            widgets,
            detailConfig,
            modelName,
            formConfig,
            tableConfig,
            loadAll,
            loadOne,
            save,
            setDataProviderName,
        }}>
            {children}
        </DataProviderContext.Provider>
    );
};

export const useDataProvider = (dataProviderName, multipart = false) => {

    const items = useSelector(state => state[dataProviderName])?.items ?? [];
    const total = useSelector(state => state[dataProviderName])?.total ?? 0;
    const loading = useSelector(state => state[dataProviderName])?.loading ?? null;
    const saving = useSelector(state => state[dataProviderName])?.saving ?? null;
    const success = useSelector(state => state[dataProviderName])?.success ?? null;
    const widgets = useSelector(state => state[dataProviderName])?.widgets ?? [];
    const item = useSelector(state => state[dataProviderName])?.item ?? null;
    const errorMessage = useSelector(state => state[dataProviderName])?.errorMessage ?? "";
    const errors = useSelector(state => state[dataProviderName])?.errors ?? {};
    const detailConfig = useSelector(state => state[dataProviderName])?.detailConfig ?? {};
    const modelName = useSelector(state => state[dataProviderName])?.modelName ?? '';
    const formConfig = useSelector(state => state[dataProviderName])?.formConfig ?? {};
    const tableConfig = useSelector(state => state[dataProviderName])?.tableConfig ?? {};

    const dispatch = useDispatch();
    const dataProviderActions = createAction(dataProviderName, multipart)

    const loadAll = (filterModel, sortModel, page, per_page) => {
        dispatch(dataProviderActions.getAll(filterModel, sortModel, page, per_page))
    }

    const loadOne = (id) => {
        return dispatch(dataProviderActions.getOne(id))
    }

    const save = (values) => {
        dispatch(dataProviderActions.save(values))
    }

    return {
        items,
        total,
        loading,
        saving,
        success,
        item,
        errorMessage,
        errors,
        widgets,
        detailConfig,
        modelName,
        formConfig,
        tableConfig,
        loadAll,
        loadOne,
        save,
    }

};



