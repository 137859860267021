import React from 'react';
import { Box } from '@mui/joy';

const CellBadge = ({ value }) => {

    return (
        <Box className={"list-element-cell cell-badge"}>
            <span className="name" title={value}>{value}</span>
        </Box>
    );
}

export default CellBadge