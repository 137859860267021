import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ListItemButton } from "@mui/joy";
import InputIcon from '@mui/icons-material/Input';
import { useDataProvider } from "../../../../../mtska-frontend-data-provider";

const returnTo = process.env.REACT_APP_AUTH0_LOGOUT_RETURN_TO


const Auth0LogoutButton = (props) => {

  const { logout } = useAuth0();

  const { loadOne } = useDataProvider('auth/token')


  const handleClick = () => {

    localStorage.clear()
    logout({ logoutParams: { returnTo: returnTo } }).then(
      () => {
        loadOne('disconnect').then(
          () => localStorage.clear()
        )
        
      }
    )
  }


  return (
    <ListItemButton
      {...props}
      onClick={handleClick}
      variant="plain">
      <InputIcon />
      Logout
    </ListItemButton>
  );
};

export default Auth0LogoutButton;

