import React from "react";
import { Box } from "@mui/joy";

const PersonAvatar = ({firstname, lastname, size = "default", url = null}) => {

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String){
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"person-avatar person-avatar-"+size}>
            {url ? (
                <img src={url} />
            ) : (
                <img src="https://thispersondoesnotexist.com?ciao" />
            )}
            {!url && (
                <span className="name" title={firstname + " " + lastname}>{getFirstCharacter(firstname) + getFirstCharacter(lastname)}</span>
            )}
        </Box>
    )
}

export default PersonAvatar;