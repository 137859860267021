import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import PersonAvatar from '../Snippet/PersonAvatar';
import { Icon } from '../../assets';

const RelationshipDriverDelegate = ({ k, v, editing, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const printableExtensions = ['JPG', 'JPEG', 'PNG', 'SVG', 'WEBP'];

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <PersonAvatar firstname={v?.firstname} lastname={v?.lastname} url={v?.url} />
                <Box className="name">
                    <span className="name">{v?.firstname + " " + v?.lastname}</span>
                </Box>
                <Box className={"coupled relativitydegree"}>
                    <span className={"label"} >{t('Relative')}</span>
                    <span className={"value"} title={t(v?.relationship)}>{t(v?.relationship)}</span>
                </Box>
                <Box className={"coupled licence"}>
                    <span className={"label"} >{t('Driver licence')}</span>
                    <span className={"value"} title={t(v?.licenceid)}>{t(v?.licenceid)}</span>
                </Box>
                <Box className={"icon-file icon-" + (printableExtensions.includes(v?.authorizationextension) ? 'preview' : 'type')}>
                    {
                        printableExtensions.includes(v?.authorizationextension) && (
                            <img src={v?.url} className="filepreview" />
                        )
                    }
                    {
                        !printableExtensions.includes(v?.authorizationextension) && (
                            <>
                                <Icon icon="faFile" className="fileicon" />
                                <Box className="extension">{v?.authorizationextension}</Box>
                            </>
                        )
                    }
                </Box>
                <Box className={"authorization norightborder"}>
                    <span className={"label"} >{t('Co-driver authorization')}</span>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipDriverDelegate



