import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next'

const CellCurrency = ({ value, type }) => {
    const { t, i18n } = useTranslation();
    const currencyformatter = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'EUR' });
    
    return <Box className={"list-element-cell cell-" + type}>{ (value) ? currencyformatter.format(value) : '-' }</Box>
}

export default CellCurrency