import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const CellTranslatable = ({ value, type = 'translatable' }) => {
    const { t } = useTranslation();
    return (
        <Box className={"list-element-cell cell-" + type}>
            {t(value)}
        </Box>
    )
}

export default CellTranslatable