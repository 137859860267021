import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Icon } from '../../assets';

const RelationshipFileGeneric = ({ k, v, editing, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    const byteValueNumberFormatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        notation: "compact",
        style: "unit",
        unit: "kilobyte",
        unitDisplay: "narrow",
    });

    const printableExtensions = ['JPG', 'JPEG', 'PNG', 'SVG', 'WEBP'];

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className={"icon-file icon-" + (printableExtensions.includes(v.extension) ? 'preview' : 'type')}>
                    {
                        printableExtensions.includes(v.extension) && (
                            <img src={v.url} className="filepreview" />
                        )
                    }
                    {
                        !printableExtensions.includes(v.extension) && (
                            <>
                                <Icon icon="faFile" className="fileicon" />
                                <Box className="extension">{v.extension}</Box>
                            </>
                        )
                    }
                </Box>
                <Box className="coupled meta">
                    <span className="label">{v.name}</span>
                    <span className="value">{byteValueNumberFormatter.format(v.size / 1024)}</span>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipFileGeneric



