import React from 'react';
import { useState, useEffect } from 'react';
import { Detail as DetailComponent } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';

const dataProviderName = 'bis/bi';

const Detail = (params) => {
    const { t } = useTranslation();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    useEffect(() => {

        loadOne(params.filters?._id)
    }, [])

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    return (
        <DetailComponent
            {...params}
            values={{ ...item?.data }} 
            modelName={modelName} 
            detailConfig={detailConfig} 
            onEditing={setEditing} 
            save={handleSave} 
            saving={saving} 
            errors={errors} 
            errorMessage={errorMessage} 
            rightButtons={[]}

            title={item?.data?.name ? item.data.name+" | "+t('edit mode') : t('New report')}
        />
    )


}

export default Detail;


