import React from 'react';
import { Box } from '@mui/joy';

const CellCount = ({ value, columnRendered }) => {

    const getValue = () => {
        return Array.isArray(value) ? value.length : "-";
    }

    const getSuffix = () => {
        return (getValue() !== "-" && columnRendered?.suffix) ? columnRendered.suffix : '';
    }

    return <Box className={"list-element-cell cell-count"}>
        {getValue()} {getSuffix()}
    </Box>
}

export default CellCount;