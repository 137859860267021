import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next'

const CellCarSetup = ({ value, type = 'carsetup'}) => {
    const { t, i18n } = useTranslation();
    return (
        <Box className={"list-element-cell cell-" + type}>
            <span className="make">{ value?.make }</span><span className="model">{ value?.model }</span><span className="setup">{ value?.setup }</span>
        </Box>
    )
}

export default CellCarSetup