import React from "react"
import InfoHeaderPerson from "./InfoHeaderPerson";
import InfoHeaderLegalEntity from "./InfoHeaderLegalEntity";
import InfoHeaderVehicle from "./InfoHeaderVehicle";

const InfoHeader = ({ view, item, control }) => {

    if (view == 'person') {
        return <InfoHeaderPerson
            view={view}
            item={item}
            control={control}
        />
    }

    if (view == 'legalEntity') {
        return <InfoHeaderLegalEntity
            view={view}
            item={item}
            control={control}
        />
    }

    if (view == 'vehicle') {
        return <InfoHeaderVehicle
            view={view}
            item={item}
            control={control}
        />
    }
};

export default InfoHeader
