import React, { Fragment, useEffect, useState } from 'react'
import { Box, ModalClose } from '@mui/joy'
import { DataGrid } from '@mui/x-data-grid'
import { Cell } from '../ListElements'
import ListToolbar from './ListToolbar'
import { useDataProvider } from '../../../mtska-frontend-data-provider'
import { Text } from '../Typography'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'
import { pick } from 'dot-object'
import Dump from '../../Dump'
import { BreadCrumb } from '../Drawer'

const listId = Math.random().toString(16).slice(2);

const pageSizeOptions = [5, 10, 15, 20, 100];

const List = ({
    title,
    apiRef,
    isDrawer,
    uid,
    handleClose,
    dataProviderName,

    defaultSelectedValues,
    enableSelection,
    disableMultipleRowSelection,

    leftButtons = [],
    rightButtons = [],

    className = "",
    limit = 20,
    filters,
    detailStack,
}) => {
    const { t } = useTranslation();
    const { items, total, loading, loadAll } = useDataProvider(dataProviderName);

    const { item: config } = useDataProvider('configs/config');
    const [tableConfig, setTableConfig] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setTableConfig(config.data.modules[dataProviderName].tableConfig);
    }, [config, dataProviderName])



    const { items: userSetting, save: saveSetting } = useDataProvider('settings/setting');


    const [columns, setColumns] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState();



    useEffect(() => {
        setColumnVisibilityModel(userSetting[`${dataProviderName}.columnVisibilityModel`])

    }, [userSetting[`${dataProviderName}.columnVisibilityModel`]])


    const [toolBarLeftButtons, settoolbarLeftButtons] = useState(leftButtons)
    const [toolBarRightButtons, settoolbarRightButtons] = useState(rightButtons)

    // FILTERS
    const [filterModel, setFilterModel] = useState({});
    useEffect(() => {
        setFilterModel(filters);
    }, [filters])

    // SORTING
    const [sortModel, setSortModel] = useState({});

    // PAGINATION
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: pageSizeOptions.includes(limit) ? limit : pageSizeOptions[0],
    });

    // LOAD DATA

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(total || 0);

    const rowCount = React.useMemo(() => {
        if (total !== undefined) {
            rowCountRef.current = total;
        }
        return rowCountRef.current;
    }, [total]);

    useEffect(() => {
        loadAll && loadAll(filterModel, sortModel, paginationModel.page + 1, paginationModel.pageSize)
    }, [paginationModel, filterModel, sortModel])


    // *******************************************************************
    // MANAGE ROW SELECTION & ASSOCIATION
    // *******************************************************************

    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [viewedItems, setViewedItems] = useState([]);





    // preset rowSelectionModel adn viewedItems
    useEffect(() => {
        if (!enableSelection) {
            return;
        }

        if (!Array.isArray(defaultSelectedValues)) {
            setRowSelectionModel([]);
            return;
        }

        const tmp = defaultSelectedValues.reduce((a, v) => ({ ...a, [v.id]: v }), {});
        setViewedItems(tmp);

        const selectedIemsIds = defaultSelectedValues.map(el => el._id);
        setRowSelectionModel(selectedIemsIds);

    }, [defaultSelectedValues, enableSelection]);

    const localHandleSubmit = () => {
        if (!enableSelection) return
        const selectedIems = rowSelectionModel.map(id => viewedItems[id])
        handleClose(uid, selectedIems)

    }

    // add items to viewedItems when age or search change.. to have the full row object available for association
    useEffect(() => {
        if (!Array.isArray(items)) return;
        const tmp = { ...viewedItems, ...items.reduce((a, v) => ({ ...a, [v.id]: v }), {}) };
        setViewedItems(tmp);
    }, [items]);





    useEffect(() => {
        if (typeof tableConfig?.columnVisibilityModel !== 'undefined') {
            //  setColumnVisibilityModel(tableConfig.columnVisibilityModel);
        }
        if (Array.isArray(tableConfig?.columns)) {
            const columns = tableConfig.columns.map((cc) => {
                return {
                    flex: 1,
                    field: cc,
                    headerName: t(cc),
                    renderCell: (params) => {
                        const columnRendered = tableConfig.columnsRendered[params?.field]
                        const drawerRoute = tableConfig.columnsRendered[params?.field]?.drawerRoute
                        const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName;
                        const val = typeof params?.row === 'object' ? pick(cc, params.row) : params.value?.value;
                        return (
                            <Fragment>
                                <Cell
                                    type={columnRendered?.type}
                                    columnRendered={columnRendered}
                                    drawerRoute={drawerRoute}
                                    dataProviderName={dataProviderName}
                                    fieldDataProviderName={fieldDataProviderName}
                                    field={params?.field}
                                    value={val}
                                    row={params.row}
                                    filters={filters}
                                    detailStack={detailStack}
                                />
                            </Fragment>
                        )
                    }
                }
            })
            setColumns(columns)
        }
    }, [tableConfig])


    return (
        <>
            {isDrawer && (
                <>
                    <Box className="drawer-section-header" >
                        <ModalClose />
                        <Text level="title-md" icon >{title}</Text>
                        <Button label="Save" className="button-main" onClick={localHandleSubmit} />

                    </Box>
                    <BreadCrumb />
                </>
            )}
            <Box className="drawer-section-content">
                <Box className={(className + " list-system listy-system-" + listId).trim()}>
                    <DataGrid
                        apiRef={apiRef}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => {
                            saveSetting({
                                name: `${dataProviderName}.columnVisibilityModel`,
                                value: newModel
                            });
                            // setColumnVisibilityModel(newModel)
                        }}
                        slots={{
                            toolbar: ListToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                leftButtons: toolBarLeftButtons,
                                rightButtons: toolBarRightButtons,
                            }
                        }}
                        disableRowSelectionOnClick
                        checkboxSelection={enableSelection}
                        disableMultipleRowSelection={disableMultipleRowSelection}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        getRowId={(row) => row._id}
                        initialState={{
                            columns: {

                            },
                        }}
                        autoHeight={true}
                        pagination
                        columns={columns}
                        rows={items ?? []}


                        paginationMode="server"
                        rowsPerPage={10}
                        loading={loading}
                        rowCount={rowCount}
                        pageSizeOptions={pageSizeOptions}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}

                        filterMode="server"
                        onFilterModelChange={setFilterModel}

                        sortingMode="server"
                        onSortModelChange={setSortModel}

                        keepNonExistentRowsSelected


                        className={"list-wrapper list-wrapper-" + listId}
                    />
                </Box>
            </Box>
        </>
    )
}

export default List



