import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'

const RelationshipSetup = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    return v ? (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                <Box className="url coupled">
                    <Box className="label">{t('Make')}</Box>
                    <Box className="value">{v?.make}</Box>
                </Box>
                <Box className="url coupled">
                    <Box className="label">{t('Model')}</Box>
                    <Box className="value">{v?.model}</Box>
                </Box>
                <Box className="url coupled">
                    <Box className="label">{t('Setup')}</Box>
                    <Box className="value">{v?.setup}</Box>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    ) : (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                non assegnato
            </ListItemContent>
        </ListItem>
    )
}


export default RelationshipSetup


