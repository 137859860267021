import React from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';
import { useTranslation } from 'react-i18next';

const Default = () => {
    const { t } = useTranslation();

    return (
        <Box>
            <List />
        </Box>
    )


}

export default Default;


