

const authHeader = (multipart = false) => {

    const token = localStorage.getItem('token')
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
    }

    if (multipart) {
        headers["Content-Type"] = "multipart/form-data";
    }
    return headers;
}

export default authHeader