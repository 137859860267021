import logoasset from '../assets/logo.svg';
import { AppContextProvider } from '../contexts';
import { ContextualizedApp } from '../app';
import '../../mtska-frontend-app-component/styles/components.scss';
import { EmptyModule } from '../../mtska-frontend-modules';

const App = ({ concreteconfig }) => {

    const config = {
        title: 'Scaffolding',
        logo: logoasset,
        modules: [
            EmptyModule,
        ],
        copyright: '© MyFleetSolution',
        privacy: {
            label: 'Privacy policy',
            url: 'https://www.myfleetsolution.it'
        },
        auth0: {
            domain: "",
            clientId: "",
            authorizationParams: {
                redirect_uri: "https://executive-consecution.evelean.com/callback",
            }
        },
        ...concreteconfig
    }

    

    return (
            <AppContextProvider concreteconfig={config}>
                <ContextualizedApp />
            </AppContextProvider>
    )
}

export default App;