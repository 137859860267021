import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const limit = 4;

const More = (value) => {
   
    const length = (typeof value === 'object') ? Object.keys(value).length : 0;

    return length > limit && (
        <ListItem className={'cell-list-item-count cell-legalentities-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    );
}

const CellLegalentities = ({ value }) => {
    const getValueAsArray = () => {
        return Array.isArray(value)  ? value.slice(0, limit) : typeof value !== null ?  [value] : [];
    }

    return (
        <Box className={"list-element-cell cell-legalentities"}>
            <List className="cell-legalentities-list">
                {getValueAsArray().map((legalentity, k) => {
                    if (!legalentity) return;
                    return (
                        <ListItem key={k} className={'cell-legalentities-list-item'}>
                            <span className="name" title={legalentity.name}>{legalentity.name}</span>
                        </ListItem>
                    );
                })}
                <More value={value} />
            </List>
        </Box>
    );
}

export default CellLegalentities