import React from "react"

import { Controller } from "react-hook-form";
import InputTextElement from "./InputTextElement";
import { useTranslation} from 'react-i18next';

const InputDateControl = ({ editing, required, name, control, label, autoFocus, placeholder, type = "text", hint, icon, haserror, disabled, readonly, mindate, maxdate, className = "" }) => {
    const {t} = useTranslation();

    if (!editing) readonly = true;

    const slotProps = {
        input: {
            min: mindate,
            max: maxdate,
        },
    };
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {
                
                if  (typeof value == typeof undefined) {
                    className = className.replace(' filled', '').replace(' empty', '')+' empty';
                } else {
                    className = className.replace(' filled', '').replace(' empty', '')+' filled';
                }
                
                return (
                    <InputTextElement
                        id={name}
                        name={name}
                        value={value}
                        type={type}
                        label={label}
                        placeholder={placeholder}
                        icon={icon}
                        hint={hint}
                    
                        error={error}
                        invalid={(error) ? invalid : haserror}
                        editing={editing}
                        required={required}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        readonly={readonly}
                    
                        className={className}
                        onChange={onChange}
                        slotProps={slotProps}
                    />
                )
            }}
        />
    );
};

export default InputDateControl;
