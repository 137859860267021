import { Default, Detail, List } from "./views";



const Module = {
    name: "bis",
    defaultRoutePath: "/bis/configuration",
    menuItem: {
        title: "BI Configurations",
        href: "/bis/configuration",
        icon: "faChartPieSimpleCircleCurrency",
        hidden:true
    },

    internalMenuItems: {
        activities: [
            {
                title: "Business Intelligence",
                href: "/bis/biviewer",
            },
            {
                title: "BI Configurations",
                href: "/bis/configuration",
            },
        ],
        wizards: []
    },

    routes: [
        {
            path: "/bis/configuration",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
    ],
    dataProviders: [
        {
            "name": "persons",
            "endpoint": "/api/persons/person",
        },
        {
            "name": "bis",
            "endpoint": "/api/bis/bi",
        },
        {
            "name": "legalentities",
            "endpoint": "/api/companies/legalentity",
        },

    ]
}



export default Module;
