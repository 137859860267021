import React from 'react'
import { Box } from '@mui/joy';
import { Button } from '../Button';
import { useDrawer } from '../Drawer'

const CellDrawer = ({filters, detailStack, value, drawerRoute}) => {

    const { activate } = useDrawer();

    return (
        <Box className={"list-element-cell cell-drawer cell-drawer-"+drawerRoute.replace('/', '-')}>

            <Button
                size="medium"
                onClick={() => activate(drawerRoute + '/detail', {
                    filters: filters,
                    detailStack: detailStack,
                })}
                // icon={openButtonIcon} 
                variant={'small'}
                className="relationship-activator" >{value}</Button>
        </Box >
    )
}


export default CellDrawer


