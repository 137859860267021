import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellBis = ({ value }) => {
    const limit = 4;
    const length = Object.keys(value).length;
    let more = null;

    if (length > limit) {
        more = <ListItem className={'cell-list-item-count cell-bis-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    const getFirstCharacters = (input, length) => {
        if (typeof input === 'string' || input instanceof String){
            return input.substring(0, length)
        }
        return 'N';
    }

    return (
        <Box className={"list-element-cell cell-bis"}>
            <List className="cell-bis-list">
                {value.map((bi, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-bis-list-item '+bi.scope}>
                            <span className={"name"} title={bi.name}>{getFirstCharacters(bi.name, 3)}</span>
                        </ListItem>
                    );
                })}
                {more}
            </List>
        </Box>
    );
}

export default CellBis;