import React from 'react';
import { Box  } from '@mui/joy';


const CellPool = ({ value }) => {
    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String) {
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"list-element-cell cell-pool"}>
            <span className="name" title={value?.name}>{ value?.name }</span>
        </Box>
    );
}

export default CellPool