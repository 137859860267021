/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { actions as authActions } from '../../../../_model/authentication/actions';
// import validate from 'validate.js';
import { Box } from '@mui/joy';
import { Auth0LoginButton } from '../Auth0';
import { Text } from '../../../../../mtska-frontend-app-component';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};



const SignIn = props => {

    const navigate = useNavigate()



    
    // const auth = useSelector();
    const loggedIn = false //useSelector(state => state.authentication.loggedIn);

    useEffect(() => {
        if (loggedIn) {
            navigate('/');

        }
    }, [loggedIn]);

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const dispatch = useDispatch();


    useEffect(() => {
        // const errors = validate(formState.values, schema);
        const errors = {};

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);


    const handleChange = event => {

        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = event => {
        event.preventDefault();

        const { email, password } = formState.values;
        if (email && password) {
            // dispatch(authActions.login(email, password));
        }

    };

    const hasError = field => formState.touched[field] && formState.errors[field] ? true : false;

    return (
        <Box className="app-login-panel">
            <img alt="Innovation Hub" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ5IiBoZWlnaHQ9IjEwNSIgdmlld0JveD0iMCAwIDE0OSAxMDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQwLjk1NCAwSDMxLjYzNTRWMTUuNTg4OUgxMTEuMDE1TDEwOC4yNjUgMTkuNDg2N0wxMDEuNjQzIDI4Ljk0NzNMOTEuMzI1MiA0My44Mzk4SDExMC42M0MxMTYuODY4IDQzLjgzOTggMTIyLjI3MSA0Ni4wNjQ5IDEyNi43NjkgNTAuNTU1MUMxMzEuMTg5IDU1LjA0NTIgMTMzLjM4NSA2MC4zOTk4IDEzMy4zODUgNjYuNjYxNUMxMzMuMzg1IDcyLjkyMzIgMTMxLjE4OSA3OC4zNTI1IDEyNi43NjkgODIuNzcwNkMxMjIuMjcxIDg3LjI1ODEgMTE2Ljg2OCA4OS40ODU5IDExMC42MyA4OS40ODU5QzEwNC4zOTMgODkuNDg1OSA5OC44ODc5IDg3LjI1ODEgOTQuMzkwNCA4Mi43NzA2Qzg5Ljk3MDMgNzguMzUyNSA4Ny43MzYyIDcyLjk5NTIgODcuNzM2MiA2Ni42NjE1VjY2LjU1NzVINzIuMTI2OVY2Ni42NjE1QzcyLjEyNjkgNzcuMjcyIDc1LjkyMTcgODYuMjg0MyA4My40NTI0IDkzLjc2MjZDOTAuOTQzIDEwMS4yNDQgMTAwLjAwNSAxMDUuMDAzIDExMC42MyAxMDUuMDAzQzEyMS4yNTYgMTA1LjAwMyAxMzAuMjE5IDEwMS4yNDYgMTM3Ljc4MSA5My43NjI2QzE0NS4yNzIgODYuMjg0MyAxNDguOTk3IDc3LjI3MiAxNDguOTk3IDY2LjY2MTVDMTQ4Ljk5NyA1Ny44MjUyIDE0Ni4yOCA0OS45NjI4IDE0MC44NDQgNDMuMDA0OEMxMzUuNDc4IDM2LjIyMDEgMTI4LjU3OCAzMS43MzI2IDEyMC4yMTMgMjkuNTc0M0wxNDAuOTU0IDBaTTMxLjYzNTQgMTAwLjIzOFY4NC42NDg5SDYzLjI3NjFWMTAwLjIzOEgzMS42MzU0Wk0zMS42MzU0IDU3Ljg3NTlWNDIuMjg3MUg2My4yNzYxVjU3Ljg3NTlIMzEuNjM1NFpNMzEuNjM1NCAwSDBWMTUuNTg4OUgzMS42MzU0VjBaTTMxLjYzNTQgNDIuMjg0NFY1Ny44NzMzSDE1LjYwOTJWODQuNjQ2MkgzMS42MzU0VjEwMC4yMzVIMFY0Mi4yODQ0SDMxLjYzNTRaIiBmaWxsPSIjMTcxQTFDIi8+CjxwYXRoIGQ9Ik0wIDBIMTQxTDEzMCAxNkgwVjBaIiBmaWxsPSIjQzQxQzFDIi8+CjxyZWN0IHk9IjE2IiB3aWR0aD0iMTYiIGhlaWdodD0iODQiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=" width="162" align="center" ></img>
            
            <Text level="h4">{process.env.REACT_APP_OWNER}</Text>
            <Text level="h1">{process.env.REACT_APP_FULL_NAME}</Text>
            <Text level="body-md">{process.env.REACT_APP_LOGIN_PROMPT}</Text>
            <Auth0LoginButton className="input-button big"/>
        </Box>
    );
};

SignIn.propTypes = {

};

// export default withRouter(SignIn);
export default SignIn;

