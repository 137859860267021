import React from "react"

import { Controller } from "react-hook-form";
import InputTextElement from "./InputTextElement";
import { useTranslation} from 'react-i18next';

const InputTextControl = ({ editing, item, required, name, control, label, autoFocus, placeholder, type = "text", hint, icon, haserror, disabled, readonly, className = "" }) => {
    const {t} = useTranslation();
    if (!editing) readonly = true;

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {
                return (
                    <>
                    <InputTextElement
                        id={name}
                        name={name}
                        value={value}
                        type={type}
                        label={label}
                        placeholder={placeholder}
                        icon={icon}
                        hint={hint}
                    
                        error={error}
                        invalid={(error) ? invalid : haserror}
                        editing={editing}
                        required={required}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        readonly={readonly}
                    
                        className={className}
                        onChange={onChange}
                    
                    />
                    </>
                )
            }}
        />
    );
};


export default InputTextControl;
