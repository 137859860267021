import React from "react";
import { Box } from "@mui/joy";

import { useTranslation, Trans } from 'react-i18next'

const VehicleRenter = ({logo, renterName, fringeBenefitValue, fringeBenefitCurrency}) => {
    const { t, i18n } = useTranslation();

    return (
        <Box className="vehicle-renter">
            <Box className="logo">
                <img src={logo} />
            </Box>
            <Box className="name">
                {renterName}
            </Box>
            <Box className="fringe">
                {t("FB")}: {new Intl.NumberFormat(i18n.resolvedLanguage, {style: 'currency', currency: fringeBenefitCurrency}).format(fringeBenefitValue / 100)}
            </Box>
        </Box>
    )
}

export default VehicleRenter;