import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { ExpirationTermometer, Tag, LegalEntityAvatar } from '../Snippet';

const RelationshipService = ({ k, v, editing, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const tagcolors = {
        installed: "success",
        ordered: "secondary",
        archived: "danger"
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className={"coupled relativitydegree"}>
                    <span className={"label"} >{t(v?.type)}</span>
                    <span className={"value"}>{t('Monthly spending')}: {new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: v?.monthlyUsageCurrency }).format(v?.monthlyUsageValue / 100)}</span>
                </Box>
                <LegalEntityAvatar name={v?.name} url={v?.logoURL} />
                <Box className="name">
                    {v?.name}
                </Box>
                <Box className={"status"}>
                    <Tag color={(tagcolors[v?.status.toLowerCase()])} size="small" label={t(v?.status.toLowerCase())} />
                </Box>
                <Box className={"analysis norightborder"}>
                    <ExpirationTermometer installationDate={v?.installationDate} expirationDate={v?.expirationDate} orderDate={v?.orderDate} expectedInstallationDate={v?.expectedInstallationDate} />
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipService



