import React from 'react';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Full, Minimal } from '../views';
import { useDataProvider } from '../../mtska-frontend-data-provider';

const Routes = ({ modules = [] }) => {

    const { loadOne: loadConfig } = useDataProvider('configs/config')
    const { loadAll: loadSettings } = useDataProvider('settings/setting')
    const { loadOne } = useDataProvider('auth/token');

    useEffect(() => {
        const t = localStorage.getItem('token');
        if (t !== null ){
            loadOne(localStorage.getItem('token'));
            loadConfig(localStorage.getItem('token'));
            loadSettings(localStorage.getItem('token'));
        }
       

    }, [])


    const protectedLoader = ({ request }) => {
        if (!localStorage.getItem('token')) {
            let params = new URLSearchParams();
            params.set("from", new URL(request.url).pathname);
            return redirect("/?" + params.toString());
        }
        return null;
    }

    const publicLoader = ({ request }) => {
        if (localStorage.getItem('token')) {
            return redirect(process.env.REACT_APP_AUTH0_DEFAULT_AUTHENTICATED_URL);
        }
        return null;
    }

    const rs = [
        {
            path: "/",
            Component: Full,
            loader: publicLoader
        },
    ];

    modules.map((m, k) => {
        m.routes.map((r, j) => {
            if (r.protected) {
                r.Component = Full
                r.loader = protectedLoader
            } else {
                r.Component = Minimal
                r.loader = publicLoader
            }
            //console.log('module '+m.name, r.path)
            rs.push(r)
        })
    });


    const router = createBrowserRouter(rs);
    return <RouterProvider router={router} />

}

export default Routes;
