import { Default, Detail, List } from "./views";



const Module = {
    name: "permissions",
    defaultRoutePath: "/people/permission",
    menuItem: {
        title: "Permissions",
        href: "/people/permission",
        icon: "faTag",
        hidden:true
    },

    internalMenuItems: {
        activities: [
            {
                title: "people",
                href: "/people/person",
            },
            // {
            //     title: "drivers",
            //     href: "/people/driver",
            // },
            // {
            //     title: "employments",
            //     href: "/people/employment",
            // },
            // {
            //     title: "users",
            //     href: "/people/user",
            // },
            {
                title: "roles",
                href: "/people/role",
            },
            {
                title: "permissions",
                href: "/people/permission",
            },
        ],
        wizards: []
    },
    routes: [
        {
            path: "/people/permission",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
    ],
   
}

export default Module;
