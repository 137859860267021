import { KPI, List, Text } from '../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { Box } from '@mui/joy';
import React from 'react';

const Empty = () => {

    const { widgets } = useDataProvider('persons');

    return (
        <Box className="module-body">
            <Box className="mtksa-chart-wrapper">
                <KPI
                    title="Report BI"
                    icon="faDashboard"
                    score={widgets.bi?.total}>
                </KPI>
                <KPI
                    title="People"
                    icon="faPeople"
                    score={widgets.person?.total}>
                </KPI>
                <KPI
                    title="Legal entities"
                    icon="faBuilding"
                    score={widgets.legalentity?.total}>
                </KPI>
            </Box>

            <Text level="h3" className="module-body-section-title">{process.env.REACT_APP_FULL_NAME}</Text>
            <Text level="body-md" className="module-body-section-subtitle">{process.env.REACT_APP_DESCRIPTION}</Text>
            
            <Box className="mtksa-chart-wrapper">
                <List dataProviderName={'bis/bi'} className='mtksa-chart' limit={5}></List>
                <List dataProviderName={'persons/person'} className='mtksa-chart' limit={5}></List>
                <List dataProviderName={'companies/legalentity'} className='mtksa-chart' limit={5}></List>
            </Box>
        </Box>
    );
};

export default Empty;
