// import _ from "lodash";

const createConstants = (dataProviderName) => {

    return {
        SAVE_REQUEST:   'SAVE_REQUEST',
        SAVE_SUCCESS:   'SAVE_SUCCESS',
        SAVE_FAILURE:   'SAVE_FAILURE',
        GETALL_REQUEST: 'GETALL_REQUEST',
        GETALL_SUCCESS: 'GETALL_SUCCESS',
        GETALL_FAILURE: 'GETALL_FAILURE',
        GETONE_REQUEST: 'GETONE_REQUEST',
        GETONE_SUCCESS: 'GETONE_SUCCESS',
        GETONE_FAILURE: 'GETONE_FAILURE',
        DELETE_REQUEST: 'DELETE_REQUEST',
        DELETE_SUCCESS: 'DELETE_SUCCESS',
        DELETE_FAILURE: 'DELETE_FAILURE',

    }


    const BASE = dataProviderName;

    return {
        SAVE_REQUEST: BASE + '_SAVE_REQUEST',
        SAVE_SUCCESS: BASE + '_SAVE_SUCCESS',
        SAVE_FAILURE: BASE + '_SAVE_FAILURE',

        GETALL_REQUEST: BASE + '_GETALL_REQUEST',
        GETALL_SUCCESS: BASE + '_GETALL_SUCCESS',
        GETALL_FAILURE: BASE + '_GETALL_FAILURE',

        GETONE_REQUEST: BASE + '_GETONE_REQUEST',
        GETONE_SUCCESS: BASE + '_GETONE_SUCCESS',
        GETONE_FAILURE: BASE + '_GETONE_FAILURE',

        DELETE_REQUEST: BASE + '_DELETE_REQUEST',
        DELETE_SUCCESS: BASE + '_DELETE_SUCCESS',
        DELETE_FAILURE: BASE + '_DELETE_FAILURE',

    }
}

export default createConstants
