import React, { useEffect, useState } from "react"
import { ajvResolver } from "@hookform/resolvers/ajv"
import { Alert, CircularProgress, Card, Box, ModalClose, Tabs, TabList, Tab, TabPanel } from "@mui/joy"
import { fullFormats } from "ajv-formats/dist/formats"
import { useForm } from "react-hook-form"
import { CardActionArea, Backdrop } from "@mui/material"
import { BreadCrumb, Button, Input, Text } from "..";
import { useTranslation } from 'react-i18next';


const Detail = ({ detailStack, filters, detailConfig, modelName, readonly = false, values, onEditing = () => { }, save = () => { }, saving, errors, errorMessage, isDrawer, rightButtons, title, header, label, icon = "" }) => {


    const { t } = useTranslation();

    // EDITING
    const [editing, setEditing] = useState(false)

    const activateEditing = () => {
        // if (readonly) return;
        onEditing(true);
        setEditing(true)
    }

    const [formValues, setFormValues] = useState({});
    var object = Array.isArray(detailStack?.stack) ?
        detailStack?.stack.reduce((obj, item) => Object.assign(obj, item), {}) : {};

    useEffect(() => {

        if (typeof values !== 'object') {
            return;
        }

        //#####################################################


        const newValues = { ...values };
        typeof detailConfig?.tabs === 'object' && Object.entries(detailConfig.tabs).map(([k, tab]) => {
            typeof tab?.fieldsets === 'object' && Object.entries(tab.fieldsets).map(([k, fieldset]) => {
                typeof fieldset?.fields === 'object' && Object.entries(fieldset.fields).map(([k, field]) => {
                    console.log("FIELD", k, field);
                    if (typeof detailStack?.inverseRelation?.[k] !== 'undefined') {
                        // we have inverseRealtion value
                        console.log("detailStack.inverseRelation",detailStack?.inverseRelationType, k, detailStack?.inverseRelation[k], values[k]);
                        if (detailStack?.inverseRelationType === 'relationship') {
                            console.log("detailStack.inverseRelation setto relationship",detailStack?.inverseRelationType, k, detailStack?.inverseRelation[k], values[k]);
                            if (Array.isArray(values[k]) && !values[k].some(e => e._id === detailStack?.inverseRelation[k]._id)) {
                                console.log("detailStack.SET stack 1",detailStack?.inverseRelationType, values[k]);
                                newValues[k] = [...values[k], detailStack?.inverseRelation[k]];
                            }
                            if (!Array.isArray(values[k])) {
                                console.log("detailStack.SET stack 2",detailStack?.inverseRelationType, values[k]);
                                newValues[k] = [detailStack?.inverseRelation[k]];
                            }

                        }
                        if (detailStack?.inverseRelationType === 'relationshipSignle' && typeof values[k] === 'undefined') {
                            console.log("detailStack.inverseRelation setto relationshipSignle", detailStack?.inverseRelationType,k, detailStack?.inverseRelation[k], values[k]);
                            newValues[k] = detailStack?.inverseRelation[k];
                        }
                    }
                })
            })
        })

        //####################################################



        setFormValues(newValues);
        // setFormValues({ ...values });
    }, [values, detailConfig])

    // FORM 
    const { handleSubmit, control, setError, setValue } = useForm({
        values: formValues,
        resolver: ajvResolver(detailConfig?.validation ?? [], { formats: fullFormats, }),
    })

    useEffect(() => {
        if (typeof errors !== 'object') return;
        Object.entries(errors).map(
            ([k, v], i) => setError(k, { type: 'server', message: v[0] })
        )
    }, [errors])

    const localHandleSubmit = (data) => {
        save(data);
    }


    return (
        <>
            {isDrawer && (
                <>
                    <Box className="drawer-section-header" >
                        <ModalClose />
                        <Text level="title-md" icon >{title}</Text>

                        <Box display={"flex"} flexDirection="row"
                            justifyContent={"end"}
                            gap={1}
                            flexGrow={1}>
                            {Array.isArray(rightButtons) && rightButtons.map((v, k) =>
                                <div key={k}>{v}</div>
                            )}
                            <Button
                                label="Save"
                                onClick={handleSubmit(localHandleSubmit)}
                            />
                        </Box>
                    </Box>

                    <BreadCrumb />
                </>
            )}
            {errorMessage && (
                <Alert color="danger">{errorMessage}</Alert>
            )}
            <Box className="drawer-section-content">
                {header}
                <Backdrop open={saving} style={{ backgroundColor: "white" }}>
                    <CircularProgress />
                </Backdrop>
                <Card className="form-wrapper">
                    <ActionArea readonly={readonly} focusVisibleClassName="none" disableRipple={editing} disableTouchRipple={editing} onClick={activateEditing}  >



                        {typeof detailConfig?.tabs === 'object' && (
                            <Tabs aria-label="Basic tabs" defaultValue={0} className="mtska-tabs" >

                                {Object.entries(detailConfig.tabs).length > 1 && (
                                    <TabList className="mtska-tab-list">
                                        {Object.entries(detailConfig.tabs).map(([k, tab]) => {
                                            return (
                                                <Tab key={k} className="mtska-tab">
                                                    {t(tab.name)}
                                                </Tab>
                                            )
                                        })}
                                    </TabList>
                                )}
                                {Object.entries(detailConfig.tabs).map(([k, tab]) => {
                                    return (

                                        <TabPanel className="mtska-tab-panel" value={parseInt(k)} key={k}>
                                            {
                                                Object.entries(tab.fieldsets).map(([k, fieldset]) => {
                                                    return (
                                                        <fieldset className="mtska-fieldset form-section-fields-content">
                                                            {Object.entries(tab.fieldsets).length > 1 && (
                                                                <Text level="title-md" className={"mtska-fieldset-title form-element fullwidth"}>{t(fieldset.name)}</Text>
                                                            )}
                                                            {
                                                                Object.entries(fieldset.fields).map(([k, field]) => {
                                                                    return <Input editing={editing} control={control} key={k} name={k} label={k} item={formValues} detailStack={detailStack} modelName={modelName} {...field} />
                                                                })
                                                            }
                                                        </fieldset>
                                                    )
                                                })
                                            }
                                        </TabPanel>
                                    )
                                })}
                            </Tabs>
                        )}

                    </ActionArea>
                </Card>
            </Box>
        </>
    )
}

const ActionArea = ({ readonly, children, focusVisibleClassName, disableRipple, disableTouchRipple, onClick }) => {

    if (readonly) return <div >{children}</div>

    return <>
        <CardActionArea
            className="form-section-fields-wrapper"
            focusVisibleClassName={focusVisibleClassName}
            disableRipple={disableRipple}
            disableTouchRipple={disableTouchRipple}
            onClick={onClick} >{children}</CardActionArea>
    </>
}

export default Detail;