import React from 'react';
import { useState, useEffect } from 'react';
import { Button, List as ListComponent, useDrawer } from '../../../../mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid'
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const List = (params) => {

   const apiRef = useGridApiRef();
   const getselected = () => {
      return [...apiRef.current.getSelectedRows()].map(([name, value]) => ({ name, value }))
   }

   const [cap, setCap] = useState();

   const { item } = useDataProvider('configs/config');
   useEffect(() => {
      if (typeof item?.data?.capabilities === 'undefined') return;
      setCap(item.data.capabilities)
   }, [item])

   const { activate } = useDrawer();
   return <>
      {cap && (
         <ListComponent
         {...params}
            apiRef={apiRef}
            enableSelection
            rightButtons={[
               <Button disabled={cap && !cap['persons/emplyment.create']} onClick={() => { activate('employments/detail', {detailStack: params.detailStack ?? {}}); }} label={"New"} />,
               <Button disabled={cap && !cap['persons/employment.import']} onClick={() => { activate('employments/importer', {detailStack: params.detailStack ?? {}}); }} label={"Import"} />,
            ]}
            dataProviderName='persons/employment' 
            title={"Employments"} />
      )}</>
}

export default List;


