import React, { useState, useEffect } from 'react';

const AppContext = React.createContext({
    currentModuleName: 'noname',
    setCurrentModuleName: () => { },
    currentModuleNavigationData: [],
    setCurrentModuleNavigationData: () => { },
    filterByLegalEntity: 0,
    setFilterByLegalEntity: () => { },
});

export const AppContextProvider = ({ children, concreteconfig }) => {

    const [currentModuleName, setCurrentModuleName] = useState();
    const [currentModuleNavigationData, setCurrentModuleNavigationData] = useState();
    const [filterByLegalEntity, setFilterByLegalEntity] = useState(localStorage.getItem('filterByLegalEntity'));
    const [loaded, setLoaded] = useState();


    useEffect(() => {
        if (!filterByLegalEntity) return;
        if (filterByLegalEntity === localStorage.getItem('filterByLegalEntity')) {
            return;
        }

        localStorage.setItem('filterByLegalEntity', filterByLegalEntity);
        loaded && window.location.reload();
       
    }, [filterByLegalEntity, loaded])

    
    useEffect(() => {
        setLoaded(true);
    }, [])


  


    const mergeDataProviders = () => {
        const dps = [{
            name: 'default'
        }];
        concreteconfig.modules.map((m) => {
            if (!Array.isArray(m.dataProviders)) return
            return m.dataProviders.map((dp) => {
                return dps.push(dp)
            })
        })
        return dps;
    }

    const config = {
        currentModuleName,
        setCurrentModuleName,
        currentModuleNavigationData,
        setCurrentModuleNavigationData,
        filterByLegalEntity,
        setFilterByLegalEntity,
        dataProviders: mergeDataProviders(),
        ...concreteconfig
    }



    return (
        <AppContext.Provider value={config}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;