import React from "react";
import { List, ListItem, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { VehicleAvatar, LegalEntityAvatar, Tag, PersonAvatar, VehicleInstalledTermometer } from "../Snippet";
import { VehicleKilometer } from "../Chart";

const InfoHeaderVehicle = ({ view, item }) => {
    const { t, i18n } = useTranslation();
    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    item = {...{
        makeName:"Audi",
        modelName:"A1",
        setupName:"30 tdi business advance",
        picture: "http://localhost:4040/images/audi-a1-citycarver-2019-side-view.png",
        driverFirstname: "Mario",
        driverLastname: "Rossi",
        driverPicture: "http://localhost:4040/images/MarioRossi.jpg",
        legalEntityName: "Rentokil Initial",
        legalEntityLogo: "http://localhost:4040/images/rentokilinitial.png",
        orderOrderNumber: "AYPO234_KEo3",
        contractContractTotal: 362.5088,
        plate:"GM678PL",
        renterName: "Arval",
        renterLogo: "http://localhost:4040/images/Arval.svg",
        contractDuration: 60,
        contractStartDate: "2023-03-07T00:00:00.000+0000",
        contractEndDate: "2028-03-06T00:00:00.000+0000",
        contractTotalKM:120000,
        lastTraceKMDate:'2024-05-21T00:00:00.000+0000',
        lastTraceKMValue:26402,
        fringeBenefitValueMonth: 123400,
        fringeBenefitCurrency: "EUR",
        status: "running",
        traceKm:{
            contractStartDate: '2023-07-12',
            contractMonths: 60,
            contractKilometers: 120000,
            contractCostKMExcess: 32,
            contractCostKMCurrency: 'EUR',
            tracingSerie: [
                { date: '2023-07-12T00:00:00.000+0000', km: 0},
                { date: '2023-08-11T00:00:00.000+0000', km: 2451},
                { date: '2023-08-20T00:00:00.000+0000', km: 4672},
                { date: '2023-10-18T00:00:00.000+0000', km: 7232},
                { date: '2023-11-29T00:00:00.000+0000', km: 8120},
                { date: '2023-12-27T00:00:00.000+0000', km: 10300},
                { date: '2024-01-30T00:00:00.000+0000', km: 13500},
                { date: '2024-03-15T00:00:00.000+0000', km: 15909},
                { date: '2024-04-14T00:00:00.000+0000', km: 20721},
                { date: '2024-05-21T00:00:00.000+0000', km: 26402}
            ]
        }
    }, ...item};
    
    return (
        <Box className={"info-header info-header-" + view}>
            <VehicleAvatar size="big" url={item.setup?.picture?.url} />
            <Box className={"info-wrapper"}>
                <Box className="info-legal-entity">
                    <LegalEntityAvatar name={item.legalEntityName} url={item.legalEntityLogo} size="small" />
                    <span className="legal-entity-name">{item.legalEntityName}</span>
                    <LegalEntityAvatar name={item.renterName} url={item.renterLogo} size="small" />
                    <span className="renter-name">{item.renterName}</span>
                </Box>
                <Box className="info-name">
                    {item.setup?.make} {item.setup?.model} <span className="secondary">{item.setup?.setup}</span>
                </Box>
                <List className="info-details">
                    <ListItem>
                        <span className="key">{t('plate')}</span>
                        <span className="value">{item.plate}</span>
                    </ListItem>
                    {/* <ListItem>
                        <span className="key">{t('order number')}</span>
                        <span className="value">{item.order?.number || '-'}</span>
                    </ListItem> */}
                    <ListItem>
                        <span className="key">{t('status')}</span>
                        <Box className="value">
                            <Tag color={"secondary"} size="small" label={t(item.status)} />
                        </Box>
                    </ListItem>
                    <ListItem>
                        <span className="key">{t('assignee')}</span>
                        <Box className="value">
                            <PersonAvatar firstname={item.driver?.firstname || item.driver?.name} lastname={item.driver?.lastname} size="small" url={item.driver?.picture?.url} />
                            {item.driver?.firstname  || item.driver?.name} {item.driver?.lastname}
                        </Box>
                    </ListItem>
                </List>
            </Box>
            <VehicleInstalledTermometer 
                contractStartDate={item.contractStartDate} 
                contractEndDate={item.contractEndDate} 
                contractTotalKM={item.contractTotalKM} 
                lastTraceKMValue={item.lastTraceKMValue} 
                lastTraceKMDate={item.lastTraceKMDate} 
                layout="aside"
            />
            <VehicleKilometer
                contractStartDate={item.traceKm.contractStartDate}
                contractMonths={item.traceKm.contractMonths}
                contractKilometers={item.traceKm.contractKilometers}
                contractCostKMExcess={item.traceKm.contractCostKMExcess}
                contractCostKMCurrency={item.traceKm.contractCostKMCurrency}
                tracingSerie={item.traceKm.tracingSerie}
            />
        </Box>
    )
}

export default InfoHeaderVehicle;