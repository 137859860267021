import React, { useState } from 'react';
import { Box } from '@mui/joy';
import { Button } from '../Button';
import { Text } from '../Typography';

const Confirm = ({ title, oklabel = "OK", okcallable, kolabel = "NO", kocallable, children }) => {

    return (
        <Box className="dialog dialog-confirm">
            <Box className="dialog-backdrop"></Box>
            <Box className="dialog-content">
                {(title && (
                    <Text level="h3">{title}</Text>
                ))}

                <Text level="body-md">{children}</Text>

                <Box className="dialog-actions">
                    <Button variant="soft" className="dialog-ko" onClick={kocallable}>{kolabel}</Button>
                    <Button variant="solid" className="dialog-ok" onClick={okcallable}>{oklabel}</Button>
                </Box>
            </Box>
        </Box>
    )

}

export default Confirm;