import { react } from 'react';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

export default function Icon({ icon, className, family = 'fal' }) {

    const [realIcon, setRealIcon] = useState();
    const getIcon = () => {
        return (family === 'fal') ? fal[icon]
            : ((family === 'fas') ? fas[icon]
                : fab[icon])
    }

    useEffect(() => {
        const i = (family === 'fal') ? fal[icon] : ((family === 'fas') ? fas[icon] : fab[icon]);
        setRealIcon(typeof i === 'undefined' ? null : i);
    }, [icon, family])

    return realIcon ? (<FontAwesomeIcon
        icon={realIcon}
        className={className} />) : <></>


}
