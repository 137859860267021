import React from "react"
import { useTranslation } from 'react-i18next'
import { Text } from "../Typography";

const SectionTitle = ({ level = 'title-sm', icon, className, label }) => {
    const { t } =  useTranslation();

    className = (typeof className != typeof undefined) ? className+" form-element" : "form-element";
    return <Text level={level} icon={icon} className={className} children={t(label)} />
}

export default SectionTitle