import React, { useState, useRef, useEffect } from 'react';
import { useForm, useWatch } from "react-hook-form"
import * as XLSX from 'xlsx';
import { Box, Alert } from '@mui/joy';
import { Button } from '../Button';
import { Input } from '../FormElements';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import Previewer from './Previewer';

const Importer = ({ dataProviderName, multipart, isDrawer, uid, handleClose, filters, rightButtons, title, label, icon = "" }) => {


    const { item: config } = useDataProvider('configs/config');
    const { save: saveImport, errorMessage, errors, success } = useDataProvider('imports/job');
    const { loadAll: loadMappings, items: mappingsDb } = useDataProvider('imports/mapping');

    useEffect(() => {

        if (!Array.isArray(mappingsDb)) return;
        if (mappingsDb.length === 0) return;
        const mappingValues = mappingsDb.reduce((a, v) => {
            return { ...a, [v.name]: v.mapping };
        }, {});
        setMappings(mappingValues);
    }, [mappingsDb]);

    useEffect(() => {
        loadMappings(filters);
    }, []);


    const [model, setModel] = useState();

    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setModel(config.data.modules[dataProviderName].importModel);
    }, [config, dataProviderName])

    const [mappings, setMappings] = useState({});


    const form = useRef();
    const { handleSubmit, control, setValue } = useForm();
    const selectedMappingNewNameWatcher = useWatch({
        control,
        name: 'selectedMappingNewName'
    });

    const selectedMappingWatcher = useWatch({
        control,
        name: 'selectedMapping'
    });

    const selectedFileWatcher = useWatch({
        control,
        name: 'fileUploader'
    });

    useEffect(() => {
        setSelectedMappingNewName(selectedMappingNewNameWatcher);
    }, [selectedMappingNewNameWatcher])

    useEffect(() => {
        handleSelectedMapping(selectedMappingWatcher);
    }, [selectedMappingWatcher])

    useEffect(() => {
        if (document.querySelector('[name="fileUploader"]') === null) {
            return;
        }

        handleFile(document.querySelector('[name="fileUploader"]').files[0]);
    }, [selectedFileWatcher])

    const [excelFile, setExcelFile] = useState(null);
    const [error, setError] = useState(null);
    const [excelData, setExcelData] = useState(null);
    const [importStage, setImportStage] = useState(1);


    const [editing, setEditing] = useState(false);
    const [mapping, setMapping] = useState({});
    const [selectedMapping, setSelectedMapping] = useState();
    const [defaultMapping, setDefaultMapping] = useState({});


    const [selectedMappingNewName, setSelectedMappingNewName] = useState('');

    useEffect(() => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
            setExcelData(data);
            setImportStage(2);
        }
    }, [excelFile])

    // useEffect(() => {
    //     setValue('mapping', JSON.stringify(mapping));
    // }, [mapping]);

    // useEffect(() => {
    //     setValue('mappingreverse', JSON.stringify(mappingFieldsValue));
    // }, [mappingFieldsValue]);

    // --------
    // CARICAMENTO DEL FILE
    // --------
    const handleFile = (selectedFile) => {
        const validExtensions = ['application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (selectedFile && validExtensions.includes(selectedFile.type)) {
            setError(null);
            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setExcelFile(e.target.result);
            }
        } else if (selectedFile) {
            setError('File must be a valid file type (xls, xlsx, csv)');
            setExcelFile(null);
        } else {
            setError(null);
            setExcelFile(null);
        }
    }

    // --------
    // GESTIONE DEI DUE STATI DI SUBMIT DELLA FORM
    // --------
    const handleFileSubmit = (data) => {
        if (Object.keys(mapping).length === 0) {
            setError('Associa almeno un campo');
        } else {
            submit(data);
        }
    }


    const submit = (data) => {
        console.log('Data in uscita', data)

        const mappedExcelData = excelData.map((row) => {
            const mappedItem = {};
            for (const [key, value] of Object.entries(mapping)) {
                console.log("MAPPING", mapping, row, key, value, mappedItem);
                mappedItem[value] = row[key];
            }

            return mappedItem;
        });

        setEditing(true);
        saveImport({ ...data, excelData: mappedExcelData, model: dataProviderName });
    }


    // --------
    // SELEZIONE DI UNA RICETTA DI MAPPING ESISTENTE
    // la selezione di un mapping esistente deve resettare eventuali mapping manuali e caricare lo schema desiderato
    // --------
    const handleSelectedMapping = (v) => {

        const newDefaultMapping = getMappingFromId(v);
        if (typeof newDefaultMapping === 'undefined') {
            setSelectedMapping(null);
            setDefaultMapping({});
            return;
        }

        setSelectedMapping(v);
        setDefaultMapping(getMappingFromId(v).mapping ?? {})
    }

    const getMappingsOptions = () => {
        const options = Object.keys(mappingsDb).length > 0 ? Object.values(mappingsDb).map((v, k) => {
            return { value: v.id, label: v.name };
        }) : []
        return options;
    }

    const getMappingFromId = (id) => {
        return mappingsDb.find(obj => {
            return obj.id === id
        })

    }

    const handleOnMappingChange = (newMapping) => {
        setValue('mapping', newMapping);
        setMapping(newMapping);
    }

    useEffect(() => {
        if (success && editing) {

            (async () => {
                try {
                    await handleClose(uid);
                    setEditing(false);
                } catch (e) { }
            })()

        }
    }, [success, editing])


    return (
        <Box className="drawer-section-content">
            <Box className="importer">
                <Box className="importer-form-wrapper">

                    <Box ref={form} className="importer-form" >

                        <Input
                            type="hidden"
                            name="mapping"
                            defaultValue={JSON.stringify(mapping)}
                            control={control}
                            editing={true}
                        />


                        {importStage === 1 && (
                            <Box className={((importStage === 1) ? "import-stage import-stage-visible" : "import-stage import-stage-hidden")}>
                                <Input
                                    label={'Carica un file'}
                                    type="file"
                                    name="fileUploader"
                                    control={control}
                                    editing={true}
                                />
                            </Box>
                        )}
                        {importStage === 2 && (
                            <Box className={((importStage === 2) ? "import-stage import-stage-visible" : "import-stage import-stage-hidden")}>
                                {mappings && Object.keys(mappings).length > 0 && (
                                    <Input
                                        label={'Utilizza un mapping esistente'}
                                        type="select"
                                        name="selectedMapping"
                                        control={control}
                                        editing={true}
                                        addEmptyOption={true}
                                        options={getMappingsOptions()}
                                    />
                                )}

                                <Input
                                    label={selectedMapping ? 'Rinomina mapping in' : 'Salva mapping come'}
                                    type="text"
                                    name="selectedMappingNewName"
                                    control={control}
                                    editing={true}
                                />
                                <Box className="form-element import-actions">
                                    <Button variant="solid" className="importer-submit" onClick={() => { handleSubmit(handleFileSubmit)() }}>Import</Button>
                                </Box>
                            </Box>
                        )}
                        {(editing && errorMessage !== "") &&  (<Alert color="danger" >{errorMessage}</Alert>)}

                    </Box>
                </Box>
                {importStage === 2 && (
                    <Box className="importer-data-wrapper">
                        <Previewer
                            excelData={excelData}
                            model={model}
                            defaultMapping={defaultMapping}
                            onMappingChange={handleOnMappingChange}
                        />
                    </Box>
                )}

                {error && (
                    <Alert className="importer-error">{error}</Alert>
                )}

            </Box>
        </Box>
    );
}

export default Importer;