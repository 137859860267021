import React, { useEffect, useState } from "react"
import { Box, ModalClose } from "@mui/joy"
import { fullFormats } from "ajv-formats/dist/formats"
import { CardActionArea, Backdrop } from "@mui/material"

import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { Button, Text, Importer as ImporterComponent } from "../../../../mtska-frontend-app-component";

const Importer = (params) => {

   return <>
      {params.isDrawer && (
         <Box className="drawer-section-header" >
            <ModalClose />
            <Text level="title-md" icon >Import Employments</Text>
         </Box>
      )}
      <ImporterComponent dataProviderName='persons/employment' {...params} filters="persons/employment"></ImporterComponent>
   </>

}

export default Importer;


