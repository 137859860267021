import { React } from "react";
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './store';
import { DataProvider } from "./DataProviderContext";

export const DataProviderRedux = ({ children }) => {

    return (
        <ReduxProvider store={store}>
            <DataProvider>
                {children}
            </DataProvider>
        </ReduxProvider>
    );
};

