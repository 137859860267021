import React from "react"

import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { Icon } from '../../assets';
import { useTranslation} from 'react-i18next';

const InputTextElement = ({ id, name, value, type, label, placeholder, icon, hint, error, invalid, editing, required, autoFocus, disabled, readonly, className, onChange, slotProps }) => {
    const {t} = useTranslation();

    if (!editing) readonly = true;

    let outerClass = "form-element-control";
    outerClass += (editing) ? " editing" : " reading";
    outerClass += (error) ? " error" : "";
    outerClass += (required) ? " required" : "";
    outerClass += (disabled) ? " disabled" : "";
    outerClass += (readonly) ? " readonly" : "";

    return (
        <div className={("form-element " + type + " " + className).trim()}>
            <FormControl error={error} className={(invalid) ? outerClass + " form-element-error" : outerClass}>
                <FormLabel className="form-element-label" htmlFor={name}>{t(label)}</FormLabel>
                <Input
                    id={id}
                    type={type}
                    name={name}
                    value={value}
                    error={error}
                    onChange={onChange}
                    variant="outlined"
                    autoComplete="off"
                    className="form-element-field"

                    autoFocus={autoFocus}
                    placeholder={t(placeholder)}
                    disabled={disabled}
                    readOnly={readonly}
                    required={required}

                    startDecorator={(icon) ? (
                        <Icon icon={icon} />
                    ) : (
                        <></>
                    )}
                    endDecorator={(invalid) ? (
                        <Icon icon="faCircleExclamation" />
                    ) : (
                        <></>
                    )}

                    slotProps={slotProps}
                />
                <FormHelperText className="form-element-hint">
                    {(invalid) ? (
                        <>
                            {error ? error.message : 'Messaggio di errore'}
                        </>
                    ) : (
                        <>
                            {hint}
                        </>
                    )}
                </FormHelperText>
            </FormControl>
        </div>
    );
};


export default InputTextElement;
