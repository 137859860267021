import React from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Report BI"
                icon="faDashboard"
                score={22}>
            </KPI>
            <KPI
                title="People"
                icon="faPeople"
                score={12}>
            </KPI>
            <KPI
                title="Legal entities"
                icon="faBuilding"
                score={33}>
            </KPI>
        </Box>
        <List />
    </>

}

export default Default;


