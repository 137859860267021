import React from 'react';
import { Box } from '@mui/joy';
import { Icon, KPI } from '../../../../mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';

const Default = () => {
    const { t } = useTranslation();

    return (
        <Box>
            <h1 style={{ marginTop: 0 }}>{process.env.REACT_APP_DASHBOARD_TITLE}</h1>
            <p>{process.env.REACT_APP_DASHBOARD_SUBTITLE}</p>
            <ul className="dashboard-menu">
                <li className="dashboard-menu-item">

                    <Icon icon="faDashboard" />
                    <h2 className="menu-item-title">{t('Business intelligence')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item"><a href="/bis/biviewer">{t('Operational Report')}</a></li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faFilter" />
                    <h2 className="menu-item-title">{t('Operation')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Leads')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Proposals')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Sales')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Acquisitions')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faMegaphone" />
                    <h2 className="menu-item-title">{t('Marketing')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('GPT')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Photo')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Matterport')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Content')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Newsletter')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faMessageDollar" />
                    <h2 className="menu-item-title">{t('Finance')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Costs')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Ebitda')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item">

                    <Icon icon="faSuitcase" />
                    <h2 className="menu-item-title">{t('Sales support')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Customer welcome kit')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Farming material')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Opportunities')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Sister interrogation')}</li>
                        <li className="dashboard-submenu-item">{<a href="https://it.casafari.com/login" target="_blank">Casafari</a>}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faPeople" />
                    <h2 className="menu-item-title">{t('Human Resources')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Talent acquisition')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Talent management')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Internal communications')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Incentive')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Knowledge base')}</li>
                    </ul>

                </li>
                <li className="dashboard-menu-item dimmed">

                    <Icon icon="faCog" />
                    <h2 className="menu-item-title">{t('Settings')}</h2>
                    <ul className="dashboard-submenu">
                        <li className="dashboard-submenu-item hidden">{t('Areas')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Shops')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Users')}</li>
                        <li className="dashboard-submenu-item hidden">{t('Goals')}</li>
                    </ul>

                </li>
            </ul>
        </Box>
    )

}

export default Default;


