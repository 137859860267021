import React from "react";
import { useLocation } from 'react-router-dom';
import { Box, List, ListItem } from '@mui/joy';
import { Anchor } from "../Typography";

import { useTranslation } from 'react-i18next';

const AppMenu = (props) => {
    const { t } = useTranslation();
    const { modules } = props;
    const location = useLocation();
    
    return (
        <Box className="app-menu">
            <List className="app-menu-portion">
                {modules.map((module, k) => {
                    if (!module.hasOwnProperty('menuItem') || module.menuItem.hidden === true) {
                        return;
                    }

                    let isSelected = false;
                    if (location.pathname == module.menuItem.href) {
                        isSelected = true;
                    } else if (Array.isArray(module.internalMenuItems?.activities)) {
                        for (let i = 0; i < module.internalMenuItems.activities.length; i++) {
                            if (location.pathname == module.internalMenuItems.activities[i].href) {
                                isSelected = true;
                                break;
                            }
                        }
                    } 

                    return (
                        <ListItem key={k} className={(isSelected) ? 'app-menu-item app-menu-item-current' : 'app-menu-item'}>
                            <Anchor icon={module.menuItem.icon} href={module.menuItem.href} underline="none">
                                <span className="name">{t(module.menuItem.title)}</span>
                            </Anchor>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}

export default AppMenu;