import React from 'react';
import { Box } from '@mui/joy';
import { Icon } from '../../assets';

const CellBoolean = ({ value, type = 'boolean' }) => {
    return (
        <Box className={"list-element-cell cell-" + type + " cell-"+type+"-"+((value) ? "true" : "false")}>
            {value ? (
                <Icon icon="faCircleCheck" />
            ) : (
                <Icon icon="faCircleXmark" />
            )}
        </Box>
    )
}

export default CellBoolean