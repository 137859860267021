import React from "react";
import { Text } from '../Typography';
import { useTranslation } from 'react-i18next';

const ActiveModuleName = ({currentModuleName}) => {    
    const { t } = useTranslation();
    return (
        <div className="modulename">
            <Text level="h2">{t(currentModuleName)}</Text>
        </div>
    );
}

export default ActiveModuleName;