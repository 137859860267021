import React from 'react';
import List from './List';

const Default = () => {

    return <>
        <List />
    </>

}

export default Default;


