import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellCars = ({ value }) => {
    const limit = 1;
    const length = Object.keys(value).length;
    let more = null;

    if (length > limit) {
        more = <ListItem className={'cell-list-item-count cell-cars-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String){
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        <Box className={"list-element-cell cell-cars"}>
            <List className="cell-cars-list">
                {value.map((car, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-cars-list-item'}>
                            <span className="name" title={car.plate}>{car.plate}</span>
                        </ListItem>
                    );
                })}
                {more}
            </List>
        </Box>
    );
}

export default CellCars;