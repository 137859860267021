import React from "react";
import { Box } from "@mui/joy";

const VehicleAvatar = ({size = "default", url = null}) => {

    return (
        <Box className={"vehicle-avatar vehicle-avatar-"+size}>
            {url && (
                <img src={url} />
            )}
            {!url && (
                <span className="name">todo:mettere silhouette</span>
            )}
        </Box>
    )
}

export default VehicleAvatar;