import { React } from "react";
import { useDrawer } from '.';
import { Box } from '@mui/joy'
import { useTranslation } from 'react-i18next'

export const BreadCrumb = ({ children, modules }) => {
    const { t } = useTranslation();

    const { baseRoute, actives } = useDrawer();

    return (
        <Box className="drawer-section-breadcrumb">
            <span>{baseRoute.routeName}</span>
            {Array.isArray(actives) && actives.map((act, k) => (
                <span key={k}>{t(act.routeName)}</span>
            ))}
        </Box>
    );
};

