import React from 'react';
import { useState, useEffect } from 'react';
import { Button, List as ListComponent, useDrawer } from '../../../../mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid'
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';

const List = (params) => {
    const { t } = useTranslation();
    const apiRef = useGridApiRef();
    const getselected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name, value]) => ({ name, value }))
    }

    const [cap, setCap] = useState();

    const { item } = useDataProvider('configs/config');
    useEffect(() => {
        if (typeof item?.data?.capabilities === 'undefined') return;
        setCap(item.data.capabilities)
    }, [item])

    const { activate } = useDrawer();
    return <>
        {cap && (
            <ListComponent
                {...params}
                apiRef={apiRef}
                enableSelection
                rightButtons={[
                    <Button
                        disabled={cap && !cap['auth/permissions.create']}
                        onClick={() => {
                            activate('permissions/detail', {detailStack: params.detailStack ?? {}});
                        }}
                        label={t("New")}
                    />,
                ]}
                dataProviderName='auth/permission'
                title={"Permissions"} />
        )}</>
}

export default List;


