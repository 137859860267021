import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer} from './reducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const loggerMiddleware = createLogger();

const enhancer = composeEnhancers(
  applyMiddleware(
    thunk,
  //  loggerMiddleware
  )
);

const loadState = () => {
  try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
          return undefined;
      }
      return JSON.parse(serializedState);
  } catch (err) {
      return undefined;
  }
};

const saveState = (state) => {
  try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
  } catch {
      // ignore write errors
  }
};


const persistedState = loadState();


const store = createStore(
  reducer,
  persistedState,
  enhancer
);

store.subscribe(() => {
  saveState(store.getState());
});

export {store};


