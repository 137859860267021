import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next'

const CellStatus = ({ value, type = 'carstatus'}) => {
    const { t, i18n } = useTranslation();
    return (
        <Box className={"list-element-cell cell-" + type}>
            <span className={"status status-"+value}>{ t(value) }</span>
        </Box>
    )
}

export default CellStatus