import React from "react";
import { List, ListItem, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { PersonAvatar, LegalEntityAvatar, Tag } from "../Snippet";
import { Anchor } from "../Typography";
import { Input } from "../FormElements";

const InfoHeaderPerson = ({ view, item, control }) => {
    const { t, i18n } = useTranslation();
    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    const isDriver = () => {
        return (Array.isArray(item?.employments) &&
            item?.employments.length > 0 &&
            typeof item?.employments[0].pool === 'object' &&
            item?.employments[0].pool !== null
        )
    }

    const isDriverPool = () => {
        return (Array.isArray(item?.employments) &&
            item?.employments.length > 0 &&
            typeof item?.employments[0].pool === 'object' &&
            item?.employments[0].pool !== null &&
            Array.isArray(item?.employments[0].pool.cars) && 
            item?.employments[0].pool.cars.length > 1
        )
    }

    return (
        <Box className={"info-header info-header-" + view}>
            <PersonAvatar firstname={item?.firstname} lastname={item?.lastname} size="big" url={item?.picture} />
            <Box className={"info-wrapper"}>
                <Box className="info-legal-entity">
                    <LegalEntityAvatar name={item?.legalEntity?.name} url={item?.legalEntity?.logo?.url} size="small" />
                    {item?.legalEntity?.name}
                </Box>
                <Box className="info-name">
                    {item?.firstname} {item?.lastname}
                </Box>
                <Box className="info-appandcompanyroles">
                    {
                        (
                            isDriver()
                        ) && (
                            <Tag color={"secondary"} size="medium" label={t("Driver")} />
                        )
                    }
                    {
                        (
                            isDriverPool()
                        ) && (
                            <Tag color={"secondary"} size="medium" label={t("Driver Pool")} />
                        )
                    }
                    <Tag color={"neutral"} size="medium" label={item?.employments?.[0]?.role + "@" + item?.employments?.[0]?.legalEntity?.name} />
                </Box>
                <List className="info-contacts">
                    <ListItem className="info-contacts-email">
                        <Anchor href={"mailto:" + item?.email} icon="faEnvelope" iconfamily='fal'>{item?.email}</Anchor>
                    </ListItem>
                    <ListItem className="info-contacts-telephone">
                        <Anchor href={"tel:" + item?.telephone} icon="faPhone" iconfamily='fal'>{item?.telephone}</Anchor>
                    </ListItem>
                    {item?.hasWhatsapp && (
                        <ListItem className="info-contacts-whatsapp">
                            <Anchor href={"https://wa.me/" + item?.telephone.replace(/[\+\ \-\_]/g, '')} icon="faWhatsapp" iconfamily='fab'></Anchor>
                        </ListItem>
                    )}
                    {item?.hasTelegram && (
                        <ListItem className="info-contacts-telegram">
                            <Anchor href={"https://t.me/" + item?.telephone.replace(/[\+\ \-\_]/g, '')} icon="faTelegram" iconfamily='fab' className></Anchor>
                        </ListItem>
                    )}
                </List>
            </Box>
            <Box className={"info-activity-wrapper"}>
                {/* <Input
                    type="switch"
                    name={"active"}
                    control={control}
                    ischecked={item?.active}
                    editing={true}
                    required={false}
                    checkedlabel={t("active")}
                    uncheckedlabel={t("inactive")}
                /> */}
                <Box className={"info-lastlogin"}>
                    <span className="key">
                        {t('Last login')}
                    </span>
                    <span className="value">
                        {dateformatter.format(new Date())}
                    </span>
                    <span className="key">
                        {t('Number of logins')}
                    </span>
                    <span className="value">
                        {parseInt(Math.random() * 100)}
                    </span>
                </Box>
            </Box>
        </Box>
    )
}

export default InfoHeaderPerson;